import React from 'react'

function Header() {
  return (
    <>
    <div>NTHNLBDKY</div>
    <div>Path to Learning React</div>
    </>
  )
}

export default Header